
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.wrapper {
    background-image: url("/assets/images/bg4Opt.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: #acd6f3;
    @media screen and (max-width: 414px) {
        background-position: right bottom;
        background-size: 90%;
    }
}
.main-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
    align-items: flex-start;
    padding-top: 1vh;
    @include md {
        flex-direction: row;
        padding-top: 3vh;
        justify-content: center;
    }
    min-height: calc(100vh - 50px);

    .form {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
        background-color: #fff;
        border-radius: 10px;
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 600px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid #4c98b1;
        margin-top: 3vh;

        @include lg {
            padding: 40px;

            @media screen and (max-width: 1200px) {
                max-width: 500px;
            }
        }

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $bestzantaclawyers-orange !important;
            transition: all 0.2s ease-in-out;

            font-size: 15px;
            span svg {
                position: relative;
                top: 2px;
            }
            @include lg {
                max-width: 450px !important;
                font-size: 18px;
                min-height: 58px;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;

                input {
                    &:checked + label {
                        background-color: #f38620;
                        color: #fff;
                        font-weight: 700;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 2px solid #f38620;
                    background-color: #fff6ee;
                    color: #000;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    min-height: 50px;
                    @include lg {
                        min-height: 58px;
                    }
                    @media screen and (max-width: 767px) {
                        padding: 10px 5px;
                    }
                    &:hover {
                        background-color: #f38620;
                        color: #fff;
                    }
                    span {
                        font-size: 18px !important;
                        @media screen and (max-width: 767px) {
                            font-size: 15px !important;
                        }
                    }
                    & > div {
                        margin-right: 15px;

                        & + span {
                            margin-top: 0;
                            margin-right: inherit;
                            width: auto;
                        }
                    }
                    & > span {
                        margin-right: auto;
                        width: 100%;
                    }

                    @include md {
                        min-height: 58px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
}

.progress-background {
    & > div:last-child {
        background-color: white;
    }
}

.progress-background {
    & > div:first-child span {
        color: #fff;
    }
    & > div:last-child {
        background-color: #f3f3f3;
    }
}
